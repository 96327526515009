<template>
  <b-card v-if="statistics" no-body class="card-statistics">
    <b-card-header>
      <b-card-title>{{ $t("dashboard.statistics") }}</b-card-title>
    </b-card-header>
    <b-card-body class="statistics-body">
      <b-row>
        <b-col
          v-for="(item, index) in statisticsItems"
          :key="item.icon"
          xl=""
          md="3"
          sm="6"
          :class="item.customClass"
        >
          <b-media no-body>
            <b-media-aside class="mr-2">
              <b-avatar size="48" :variant="item.color">
                <img :width="item.width" v-if="index === 0 || index === 3" :src="item.icon" alt="" />
                <feather-icon v-else size="24" :icon="item.icon" />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                {{ item.title }}
              </h4>
              <b-card-text class="font-small-3 mb-0">
                {{ item.subtitle }}
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BRow,
  BCol,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
} from "bootstrap-vue";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
  },
  data() {
    return {
      statistics: [],
      statisticsItems: [
        {
          icon: require("@/assets/images/pages/dashboard/riyal.svg"),
          color: "light-success",
          title: "",
          subtitle: this.$t("sales"),
          customClass: "mb-2 mb-xl-0",
          width:"35px"
        },
        {
          icon: "UsersIcon",
          color: "light-info",
          title: "",
          subtitle: this.$t("customers"),
          customClass: "mb-2 mb-xl-0",
        },
        {
          icon: "BoxIcon",
          color: "light-danger",
          title: "",
          subtitle: this.$t("home_products"),
          customClass: "mb-2",
        },
        {
          icon: require("@/assets/images/pages/dashboard/catalog_icon.svg"),
          color: "light-danger",
          title: "",
          subtitle: this.$t("home_catalogs"),
          customClass: "mb-2",
        },
        {
          icon: "ShoppingCartIcon",
          color: "light-primary",
          title: "",
          subtitle: this.$t("home_orders"),
          customClass: "mb-2",
        },
        {
          icon: "HomeIcon",
          color: "light-warning",
          title: "",
          subtitle: this.$t("vendors"),
          customClass: "",
        },
        {
          icon: "TruckIcon",
          color: "light-dark",
          title: "",
          subtitle: this.$t("delivery_boys"),
          customClass: "",
        },
      ],
    };
  },
  created() {
    this.getStatistics();
  },
  methods: {
    async getStatistics() {
      try {
        const response = (await this.$http.get("admin/statistics")).data.data;
        this.statisticsItems[0].title = response.sales;
        this.statisticsItems[1].title = response.customers_count;
        this.statisticsItems[2].title = response.products_count;
        this.statisticsItems[3].title = response.catalogs_count;
        this.statisticsItems[4].title = response.orders_count;
        this.statisticsItems[5].title = response.vendors_count;
        this.statisticsItems[6].title = response.delivery_count;
      } catch (error) {
        this.$helpers.handleError(error);
        this.isError = true;
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
