<template>
  <b-card v-if="messagesList" no-body class="card-company-table">
    <b-card-header>
      <router-link :to="{ name: 'messages' }">
        <b-card-title style="color: #2b79ad"
          >{{ $t("messages.contact_us") }}
        </b-card-title>
      </router-link>
    </b-card-header>
    <b-table :items="messagesList" responsive :fields="fields" class="mb-0">
      <template #cell(id)="data">
        <router-link
          :to="{ name: 'show-message', params: { id: data.item.id } }"
        >
          <span style="color: #2b79ad"> #{{ data.index + 1 }} </span>
        </router-link>
      </template>
      <template #cell(name)="data">
        <router-link
          :to="{ name: 'show-message', params: { id: data.item.id } }"
        >
          <span style="color: #2b79ad"> {{ data.item.name }} </span>
        </router-link>
      </template>
      <template #cell(status)="data">
        <router-link
          :to="{ name: 'show-message', params: { id: data.item.id } }"
        >
          <b-badge
            :variant="data.item.is_closed ? 'light-success' : 'light-danger'"
          >
            {{
              data.item.is_closed ? $t("messages.closed") : $t("messages.open")
            }}
          </b-badge>
        </router-link>
      </template>
      <template #cell(sender_type)="data">
        <span> {{ $t(`messages.${data.item.sender_type}`) }} </span>
      </template>
    </b-table>
  </b-card>
</template>

<script>
import {
  BCard,
  BTable,
  BAvatar,
  BImg,
  BBadge,
  BCardTitle,
  BCardHeader,
} from "bootstrap-vue";

export default {
  components: {
    BCard,
    BTable,
    BAvatar,
    BImg,
    BBadge,
    BCardTitle,
    BCardHeader,
  },

  data() {
    return {
      fields: [
        {
          key: "id",
          label: "#",
        },
        {
          key: "name",
          label: this.$t("sender_name"),
        },
        {
          key: "email",
          label: this.$t("email"),
        },
        {
          key: "phone",
          label: this.$t("phone"),
        },
        {
          key: "sender_type",
          label: this.$t("sender_type"),
        },
        {
          key: "status",
          label: this.$t("status"),
        },
      ],
      messagesList: [],
    };
  },
  created() {
    this.getMessages();
  },
  methods: {
    async getMessages() {
      try {
        const response = (
          await this.$http.get("admin/contacts?is_open=true", {
            headers: {
              "X-Page-Size": 13,
            },
          })
        ).data.data;
        this.messagesList = response;
      } catch (error) {
        this.$helpers.handleError(error);
        this.isError = true;
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-extended/include";
@import "~@core/scss/base/components/variables-dark";

.card-company-table ::v-deep td .b-avatar.badge-light-company {
  .dark-layout & {
    background: $theme-dark-body-bg !important;
  }
}
</style>
