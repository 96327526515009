<template>
  <b-card v-if="product_list" class="card-transaction" no-body>
    <b-card-header>
      <b-card-title>{{ $t("dashboard.latest_products") }}</b-card-title>
      <!-- <small class="text-muted">Total 10.4k Visitors</small> -->
      <!-- <b-dropdown
        variant="link"
        no-caret
        class="chart-dropdown"
        toggle-class="p-0"
        right
      >
        <template #button-content>
          <feather-icon
            icon="MoreVerticalIcon"
            size="18"
            class="text-body cursor-pointer"
          />
        </template>
        <b-dropdown-item href="#"> Last 28 Days </b-dropdown-item>
        <b-dropdown-item href="#"> Last Month </b-dropdown-item>
        <b-dropdown-item href="#"> Last Year </b-dropdown-item>
      </b-dropdown> -->
    </b-card-header>

    <b-card-body>
      <div
        v-for="product in product_list"
        :key="product.mode"
        class="transaction-item"
      >
        <b-media no-body>
          <b-media-aside>
            <b-avatar rounded size="42" :variant="product.avatarVariant">
              <b-img :src="filterImage(product.images)" alt="browser img" />
            </b-avatar>
          </b-media-aside>
          <b-media-body>
            <h6 class="transaction-title">
              {{ product.suffix_name }}
            </h6>
            <small>{{ product.qty }}</small>
          </b-media-body>
        </b-media>
        <div class="font-weight-bolder">
          {{ product.price + " SAR" }}
        </div>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BMediaBody,
  BMedia,
  BMediaAside,
  BAvatar,
  BDropdown,
  BDropdownItem,
  BImg,
} from "bootstrap-vue";

export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BMediaBody,
    BMedia,
    BMediaAside,
    BAvatar,
    BDropdown,
    BDropdownItem,
    BImg,
  },
  data() {
    return {
      product_list: [],
    };
  },
  created() {
    this.getProducts();
  },
  methods: {
    async getProducts() {
      try {
        const response = (await this.$http.get("web/products?latest=true", {
          headers:{
            "X-Page-Size":10
          }
        })).data
          .data;
        this.product_list = response;
      } catch (error) {
        this.$helpers.handleError(error);
        this.isError = true;
      } finally {
        this.isLoading = false;
      }
    },
    filterImage(images) {
      let img = null;
      images.map((el) => {
        if (el.is_main) {
          img = el.path;
        }
      });
      return img;
    },
  },
};
</script>
