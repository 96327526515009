<template>
  <div>
    <Ecommerce
      v-if="globalPermission.statistics && globalPermission.statistics.index"
    />
  </div>
</template>

<script>
import Ecommerce from "./ecommerce/Ecommerce.vue";

export default {
  name: "Dashboard",
  components: {
    Ecommerce,
  },
};
</script>

<style src="./_index.scss" lang="scss" scoped />
