<template>
  <section id="dashboard-ecommerce">
    <div class="mb-2 d-flex justify-content-end">
      <b-link
        :href="`${backEndBaseUrl}uploadimages`"
        target="_blank"
        role="button"
        variant="primary"
        class="btn btn-primary"
        v-if="globalPermission.images && globalPermission.images.webUpload"
      >
        {{ $t("upload_media") }}</b-link
      >
    </div>
    <!-- <b-row class="match-height"> -->
    <!-- <b-col xl="4" md="6">
        <ecommerce-medal :data="data.congratulations" />
      </b-col> -->
    <!-- <b-col xl="8" md="6"> -->
    <ecommerce-statistics
      v-if="globalPermission.statistics && globalPermission.statistics.index"
      :data="data.statisticsItems"
    />
    <!-- </b-col> -->
    <!-- </b-row> -->

    <!-- <b-row class="match-height">
      <b-col lg="4">
        <b-row class="match-height">
          <b-col lg="6" md="3" cols="6">
            <ecommerce-order-chart :data="data.statisticsOrder" />
          </b-col>
          <b-col lg="6" md="3" cols="6">
            <ecommerce-profit-chart :data="data.statisticsProfit" />
          </b-col>
          <b-col lg="12" md="6">
            <ecommerce-earnings-chart :data="data.earningsChart" />
          </b-col>
        </b-row>
      </b-col>

      <b-col lg="8">
        <ecommerce-revenue-report :data="data.revenue" />
      </b-col>
    </b-row> -->

    <b-row class="match-height">
      <!-- Company Table Card -->
      <b-col
        v-if="globalPermission.contacts && globalPermission.contacts.index"
        lg="8"
      >
        <ecommerce-company-table />
      </b-col>
      <!--/ Company Table Card -->

      <!-- Developer Meetup Card -->
      <b-col
        lg="4"
        md="6"
        v-if="globalPermission.products && globalPermission.products.index"
      >
        <ecommerce-transactions />
        <!-- <ecommerce-meetup :data="data.meetup" /> -->
      </b-col>
      <!--/ Developer Meetup Card -->

      <!-- Browser States Card -->
      <b-col
        v-if="globalPermission.orders && globalPermission.orders.index"
        md="12"
      >
        <orders />
        <!-- <ecommerce-browser-states /> -->
      </b-col>
      <!--/ Browser States Card -->

      <!-- Goal Overview Card -->
      <b-col lg="4" md="6">
        <!-- <ecommerce-goal-overview :data="data.goalOverview" /> -->
      </b-col>
      <!--/ Goal Overview Card -->

      <!-- Transaction Card -->
      <b-col lg="4" md="6"> </b-col>
      <!--/ Transaction Card -->
    </b-row>
  </section>
</template>

<script>
import { BRow, BCol } from "bootstrap-vue";

import EcommerceMedal from "./EcommerceMedal.vue";
import EcommerceStatistics from "./EcommerceStatistics.vue";
import EcommerceRevenueReport from "./EcommerceRevenueReport.vue";
import EcommerceOrderChart from "./EcommerceOrderChart.vue";
import EcommerceProfitChart from "./EcommerceProfitChart.vue";
import EcommerceEarningsChart from "./EcommerceEarningsChart.vue";
import EcommerceCompanyTable from "./EcommerceCompanyTable.vue";
import EcommerceMeetup from "./EcommerceMeetup.vue";
import EcommerceBrowserStates from "./EcommerceBrowserStates.vue";
import EcommerceGoalOverview from "./EcommerceGoalOverview.vue";
import EcommerceTransactions from "./EcommerceTransactions.vue";
import Orders from "./Orders.vue";
import { BLink } from "bootstrap-vue";

export default {
  components: {
    BRow,
    BCol,
    EcommerceMedal,
    EcommerceStatistics,
    EcommerceRevenueReport,
    EcommerceOrderChart,
    EcommerceProfitChart,
    EcommerceEarningsChart,
    EcommerceCompanyTable,
    EcommerceMeetup,
    EcommerceBrowserStates,
    EcommerceGoalOverview,
    EcommerceTransactions,
    Orders,
    BLink,
  },
  data() {
    return {
      data: {
        statisticsOrder: {
          series: [
            {
              name: "2020",
              data: [45, 85, 65, 45, 65],
            },
          ],
        },
        statisticsProfit: {
          series: [
            {
              data: [0, 20, 5, 30, 15, 45],
            },
          ],
        },
        earningsChart: {
          series: [53, 16, 31],
        },
        revenue: {
          years: ["2020", "2019", "2018"],
          price: "25,852",
          budget: "56,800",
          revenueReport: {
            series: [
              {
                name: "Earning",
                data: [95, 177, 284, 256, 105, 63, 168, 218, 72],
              },
              {
                name: "Expense",
                data: [-145, -80, -60, -180, -100, -60, -85, -75, -100],
              },
            ],
          },
          budgetChart: {
            series: [
              {
                data: [61, 48, 69, 52, 60, 40, 79, 60, 59, 43, 62],
              },
              {
                data: [20, 10, 30, 15, 23, 0, 25, 15, 20, 5, 27],
              },
            ],
          },
        },
        meetup: {
          mediaData: [
            {
              avatar: "CalendarIcon",
              title: "Sat, May 25, 2020",
              subtitle: "10:AM to 6:PM",
            },
            {
              avatar: "MapPinIcon",
              title: "Central Park",
              subtitle: "Manhattan, New york City",
            },
          ],
          avatars: [
            {
              avatar: require("@/assets/images/portrait/small/avatar-s-9.jpg"),
              fullName: "Billy Hopkins",
            },
            {
              avatar: require("@/assets/images/portrait/small/avatar-s-6.jpg"),
              fullName: "Amy Carson",
            },
            {
              avatar: require("@/assets/images/portrait/small/avatar-s-8.jpg"),
              fullName: "Brandon Miles",
            },
            {
              avatar: require("@/assets/images/portrait/small/avatar-s-7.jpg"),
              fullName: "Daisy Weber",
            },
            {
              avatar: require("@/assets/images/portrait/small/avatar-s-20.jpg"),
              fullName: "Jenny Looper",
            },
          ],
        },
        goalOverview: {
          completed: "786,617",
          inProgress: "13,561",
          series: [83],
        },
      },
    };
  },
  computed: {
    backEndBaseUrl() {
      return process.env.VUE_APP_BACKEND_URL;
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/dashboard-ecommerce.scss";
@import "@core/scss/vue/libs/chart-apex.scss";
</style>
