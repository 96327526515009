<template>
  <b-card v-if="ordersList" no-body class="card-company-table">
    <b-card-header>
      <router-link :to="{ name: 'orders-list' }">
        <b-card-title style="color: #2b79ad"
          >{{ $t("orders.latest_orders") }}
        </b-card-title>
      </router-link>
    </b-card-header>
    <b-table :items="ordersList" responsive :fields="fields" class="mb-0">
      <template #cell(id)="data">
        <router-link :to="{ name: 'show-order', params: { id: data.item.id } }">
          <span style="color: #2b79ad"> #{{ data.index + 1 }} </span>
        </router-link>
      </template>
      <template #cell(order_code)="data">
        <router-link :to="{ name: 'show-order', params: { id: data.item.id } }">
          <span style="color: #2b79ad"> {{ data.item.order_code }} </span>
        </router-link>
      </template>
      <template #cell(customer_name)="data">
        <router-link :to="{ name: 'show-order', params: { id: data.item.id } }">
          <span style="color: #2b79ad">
            {{
              data.item.customer.first_name + " " + data.item.customer.last_name
            }}
          </span>
        </router-link>
      </template>
      <template #cell(customer_phone)="data">
        {{
          data.item.customer.phone.length
            ? data.item.customer.phone[0].phone
            : $t("g.noData")
        }}
      </template>
      <template #cell(order_date)="data">
        <span> {{ $helpers.dateTimeInFormat(data.item.created_at) }} </span>
      </template>
      <template #cell(order_time)="data">
        <span> {{ $helpers.timeInFormat(data.item.created_at) }} </span>
      </template>
    </b-table>
  </b-card>
</template>

<script>
import {
  BCard,
  BTable,
  BAvatar,
  BImg,
  BBadge,
  BCardTitle,
  BCardHeader,
} from "bootstrap-vue";

export default {
  components: {
    BCard,
    BTable,
    BAvatar,
    BImg,
    BBadge,
    BCardTitle,
    BCardHeader,
  },

  data() {
    return {
      fields: [
        {
          key: "id",
          label: "#",
        },
        {
          key: "order_code",
          label: this.$t("orders.order_number"),
        },
        {
          key: "customer_name",
          label: this.$t("orders.customer_name"),
        },
        {
          key: "customer_phone",
          label: this.$t("orders.customer_phone"),
        },
        {
          key: "final_total",
          label: this.$t("orders.total_price"),
        },
        {
          key: "current_status",
          label: this.$t("orders.status"),
        },
        {
          key: "order_date",
          label: this.$t("orders.order_date"),
        },
        {
          key: "order_time",
          label: this.$t("orders.order_time"),
        },
      ],

      // {
      //   label: "customer_name",
      //   field: "customer",
      // },
      // {
      //   label: "customer_phone",
      //   field: "phone",
      //   sortable: false,
      // },
      // {
      //   label: "total_price",
      //   field: "final_total",
      //   sortable: false,
      // },
      // {
      //   label: "delivery_boy",
      //   field: "delivery_boy",
      //   sortable: false,
      // },
      // {
      //   label: "order_place",
      //   field: "address",
      //   sortable: false,
      // },
      // {
      //   label: "total_count",
      //   field: "items_count",
      //   sortable: false,
      // },
      // {
      //   label: "status",
      //   field: "status",
      //   sortable: false,
      // },
      // {
      //   label: "order_date",
      //   field: "created_at_date",
      //   sortable: false,
      // },
      // {
      //   label: "order_time",
      //   field: "created_at_time",
      //   sortable: false,
      // },
      ordersList: [],
    };
  },
  created() {
    this.getOrders();
  },
  methods: {
    async getOrders() {
      try {
        const response = (
          await this.$http.get("admin/orders", {
            headers: {
              "X-Page-Size": 10,
            },
          })
        ).data.data;
        this.ordersList = response;
      } catch (error) {
        this.$helpers.handleError(error);
        this.isError = true;
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-extended/include";
@import "~@core/scss/base/components/variables-dark";

.card-company-table ::v-deep td .b-avatar.badge-light-company {
  .dark-layout & {
    background: $theme-dark-body-bg !important;
  }
}
</style>
